import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToasterService } from 'angular2-toaster';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { UtilisateurLogin } from '../_models/utilisateurLogin';
import { Utilisateur } from '../_models/utilisateur';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  baseUrl: string;

  constructor(
    @Inject('BASE_URL') baseUrl: string,
    private http: HttpClient,
    private toasterService: ToasterService) {
    this.baseUrl = baseUrl;
  }

  errorHandler(error: any): void {
    this.toasterService.pop('error', 'Une erreur est survenue', error);
  }

  getHttpHeaders(json: boolean = true): any {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const token = currentUser && currentUser.token;
    let headers = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
      'Content-Type': 'application/json',
      'Accept': 'q=0.8;application/json;q=0.9',
      'Authorization': 'Bearer ' + token
    });

    if (!json) {
      headers = new HttpHeaders({
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
        'Authorization': 'Bearer ' + token
      });
    }

    return headers;
  }

  la(la: string, baseTest: boolean = false): Observable<UtilisateurLogin> {
    let body = JSON.stringify({ la: la });
    if (baseTest) {
      body = JSON.stringify({ la: la, isTest: baseTest });
    }
    const observableResponse = this.http.post<Utilisateur>(this.baseUrl + 'api/auth/la', body, { headers: this.getHttpHeaders() });
    return observableResponse.pipe(map(response => this.createUser(response)));
  }

  login(username: string, password: string): Observable<UtilisateurLogin> {
    const body = JSON.stringify({ username: username, password: password });
    const observableResponse = this.http.post<Utilisateur>(this.baseUrl + 'api/auth/login', body, { headers: this.getHttpHeaders() });
    return observableResponse.pipe(map(response => this.createUser(response)));
  }

  private createUser(response: Utilisateur): UtilisateurLogin {
    const userLogin = new UtilisateurLogin();
    const user = new Utilisateur();

    localStorage.clear();

    if (!(response && response.login && response.token)) {
      userLogin.code = 400;
      return userLogin;
    }

    // login successful if there's a jwt token in the response
    const json = response;
    const login = json.login;
    const token = json.token;
    const role = json.role;

    if (login && token && role) {
      user.login = login;
      user.idenContact = json.idenContact;
      user.prenom = json.prenom;
      user.nom = json.nom;
      user.email = json.email;
      user.fonction = json.fonction;
      user.telephone = json.telephone;
      user.mobile = json.mobile;
      user.derniereConnexion = json.derniereConnexion;
      user.token = token;
      user.actif = json.actif;

      if (json.logoB64) {
        user.logoB64 = json.logoB64;
        const imgSrc = 'data:image/png;base64,' + user.logoB64;
        user.logoB64Src = imgSrc;
      }

      userLogin.code = 200;
      userLogin.utilisateur = user;
      localStorage.setItem('currentUser', JSON.stringify(user));
    }

    return userLogin;
  }

  logout(): void {
    localStorage.clear();
  }

  getCurrentUser(): Observable<Utilisateur> {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return of(currentUser);
  }

  getCurrentUserRole(): Observable<string> {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const token = currentUser && currentUser.token;

    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    return of(decodedToken.mwRole);
  }

  postChangerMotDePasse(motDePasse: string): Observable<boolean> {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    const body = JSON.stringify({ username: currentUser.login, password: motDePasse });
    return this.http.post<boolean>(this.baseUrl + 'api/auth/change-mdp', body, { headers: this.getHttpHeaders() });

    // observableResponse.pipe(catchError(err => Observable.throw(this.errorHandler(err))));
    // return observableResponse.pipe(map(response => response.json()));
  }

  postMotDePasseOublie(login: string): Observable<boolean> {
    const body = JSON.stringify({ username: login, password: '' });
    return this.http.post<boolean>(this.baseUrl + 'api/auth/recuperer-mdp', body, { headers: this.getHttpHeaders() });

    // observableResponse.pipe(catchError(err => Observable.throw(this.errorHandler(err))));
    // return observableResponse.pipe(map(response => response.json()));
  }
}
