import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'controleStatut'
})
export class MyControleStatutFormatPipe implements PipeTransform {

    transform(val: string, args): string {
        switch (+val) {
            case 1: val = 'en cours de vérification'; break;
            case 2: val = 'valide'; break;
            case 3: val = 'valide - expire bientôt'; break;
            case -1: val = 'manquant - expiré - non valide'; break;
            default: val = 'sans objet'; break;
        }
        return val;
    }
}
