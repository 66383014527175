import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalContactService } from './modal-contact.service';
import { Md5 } from 'ts-md5';
import { Contact } from '../../_models/contact';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-contact-form',
  templateUrl: './modal-contact-form.component.html',
  styleUrls: ['./modal-contact-form.component.css']
})
export class ModalContactFormComponent implements OnInit, OnDestroy {

  private sub: Subscription;
  contact: Contact = new Contact();
  gravatarUrl = 'https://www.gravatar.com/avatar/0?d=identicon';

  constructor(
    private modalContactService: ModalContactService) {
  }

  ngOnInit() {
    this.sub = this.modalContactService.getObservable().subscribe(contact => {
      this.contact = contact;
      if (this.contact && this.contact.email) {
        const gravatarHash = Md5.hashStr(this.contact.email.toLowerCase().trim());
        this.gravatarUrl = 'https://www.gravatar.com/avatar/' + gravatarHash + '?d=identicon';
      }
    });
  }

  ngOnDestroy() {
    if (this.sub) { this.sub.unsubscribe(); }
  }

  getStyle() {
    const style = { 'background-image': 'url(/assets/img/bgcontact.jpg)' };
    return style;
  }

}
