import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared.module';
import { NgxAdminLteModule } from './_template/ngx-admin-lte.module';

// components
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';

// main bootstrap
import { routing } from './app.routes';

// providers
import { DbService } from './_services/db.service';
import { AuthGuard } from './_guards/auth.guard';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxAdminLteModule,
    SharedModule,
    routing
  ],
  providers: [
    AuthGuard,
    DbService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
