import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'telephone'
})
export class MyTelephoneFormatPipe implements PipeTransform {

    transform(val: string, args): string {
        if (val && val.length === 10) {
            return val.substr(0, 2) + '.' + val.substr(2, 2) + '.' + val.substr(4, 2) + '.' + val.substr(6, 2) + '.' + val.substr(8, 2);
        }
        return val;
    }
}
