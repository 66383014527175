import { Component, OnInit, Input } from '@angular/core';
import { ModalContactService } from './modal-contact.service';
import { Contact } from '../../_models/contact';

@Component({
  selector: 'app-modal-contact',
  templateUrl: './modal-contact.component.html',
  styleUrls: ['./modal-contact.component.css']
})
export class ModalContactComponent implements OnInit {

  @Input() contact: Contact = new Contact();

  btnClass: string = 'btn-xs';
  btnLibelle: string = '';
  afficherIcone: boolean = false;
  iconeClass: string = '';

  constructor(
    private modalContactService: ModalContactService) {
  }

  ngOnInit() {
    this.btnClass = 'btn-xs';
    this.btnLibelle = 'prenom Nom';
    this.afficherIcone = false;

    if (this.contact) {
      this.btnLibelle = this.contact.prenom + ' ' + this.contact.nom;
      if (!this.afficherIcone) {
        this.iconeClass = 'invisible';
      }
    }
  }

  afficher(event: any) {
    this.modalContactService.afficherContact(this.contact);
  }

}
