export class Utilisateur {
    idenContact: number;
    login: string;
    prenom: string;
    nom: string;
    email: string;
    fonction: string;
    telephone: string;
    mobile: string;
    token: string;
    derniereConnexion: string;
    actif: boolean = false;
    role: string;

    logoB64: string;
    logoB64Src: any;
}
