import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { Suivi } from '../_models/suivi';
import { ToasterService } from 'angular2-toaster';
import { Societe } from '../_models/societe';
import { catchError, map } from 'rxjs/operators';
import { Contact } from '../_models/contact';

@Injectable({
  providedIn: 'root'
})
export class DbService {

  baseUrl: string;

  constructor(
    @Inject('BASE_URL') baseUrl: string,
    private http: HttpClient,
    private toasterService: ToasterService) {
    this.baseUrl = baseUrl;
  }

  errorHandler(error: any): void {
    this.toasterService.pop('error', 'Une erreur est survenue', error);
  }

  getHttpHeaders(json: boolean = true): any {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const token = currentUser && currentUser.token;
    let headers = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
      'Content-Type': 'application/json',
      'Accept': 'q=0.8;application/json;q=0.9',
      'Authorization': 'Bearer ' + token
    });

    if (!json) {
      headers = new HttpHeaders({
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
        'Authorization': 'Bearer ' + token
      });
    }

    return headers;
  }

  public getSuivis(): Observable<Suivi[]> {
    const observableResponse = this.http.get<Suivi[]>(this.baseUrl + 'api/suivis/historique', { headers: this.getHttpHeaders(), observe: 'response' });
    observableResponse.pipe(catchError(err => Observable.throw(this.errorHandler(err))));
    return observableResponse.pipe(map(response => this.createSuivis(response)));
  }

  private createSuivis(response: HttpResponse<Suivi[]>): Suivi[] {
    let list: Suivi[] = [];
    const json = response.body;

    if (json) {
      json.forEach(element => {
        const item: Suivi = new Suivi();
        item.iden = element.iden;
        item.typeIden = element.typeIden;
        item.libelle = element.libelle;
        item.ste = element.ste;
        item.modelePdf = element.modelePdf;
        item.dateExpiration = element.dateExpiration;
        item.dtcr = element.dtcr;
        item.controleDtmd = element.controleDtmd;
        item.controleMessage = element.controleMessage;
        item.controleStatut = element.controleStatut;
        item.envoiUtim = element.envoiUtim;
        item.envoiDtmd = element.envoiDtmd;
        item.hasPdf = element.hasPdf;
        item.hasControlePdf = element.hasControlePdf;

        item.suiviType = element.suiviType;
        if (item.suiviType) {
            item.modelePdf = item.suiviType.modelePdf;
        }

        item.statut = "";

        moment.locale('fr');
        const dateExpiration = moment(item.dateExpiration, 'YYYY-MM-DD');

        switch (item.controleStatut) {
          case 1:
            item.statut = 'en cours de vérification';
            item.statutColor = 'info';
            break;
          case 2:
            item.statut = 'valide';
            item.statutColor = 'green';
            break;
          case 3:
            item.statut = 'valide - expire bientôt';
            item.statutColor = 'green';
            break;
          case -1:
            item.statut =
              !item.hasControlePdf ? 'manquant - non valide'
                : dateExpiration.isBefore(moment()) ? 'expiré'
                  : 'manquant - non valide';
            item.statutColor = 'red';
            break;
          default:
            item.statut = 'Sans objet';
            item.statutColor = 'gray';
            break;
        }

        list.push(item);
      });
    }

    list = list.sort((a, b) => {
      if (a.controleStatut === b.controleStatut) {
        return a.libelle.localeCompare(b.libelle);
      } else {
        return a.controleStatut - b.controleStatut;
      }
    });

    return list;
  }

  public getSuivisManquants(): Observable<Suivi[]> {
    const observableResponse = this.http.get<Suivi[]>(this.baseUrl + 'api/suivis/manquants', { headers: this.getHttpHeaders(), observe: 'response' });
    observableResponse.pipe(catchError(err => Observable.throw(this.errorHandler(err))));
    return observableResponse.pipe(map(response => this.createSuivis(response)));
  }

  private _societe: Societe;

  public getSociete(): Observable<Societe> {
    if (this._societe) {
      return of(this._societe);
    }

    const response = this.http.get<Societe>(this.baseUrl + 'api/societe/get', { headers: this.getHttpHeaders(), observe: 'response' });
    return response.pipe(map(x => this._societe = x.body));
  }

  public getContacts(): Observable<Contact[]> {
    return this.http.get<Contact[]>(this.baseUrl + 'api/societe/liste-contacts', { headers: this.getHttpHeaders() });
    // observableResponse.pipe(catchError(err => Observable.throw(this.errorHandler(err))));
    // return observableResponse.pipe(map(response => this.createContacts(response)));
  }

  public getSuiviPdf(item: Suivi) {
    let params = new HttpParams();
    params = params.set('iden', item.iden.toString());

    const observableResponse = this.http.get(this.baseUrl + 'api/suivis/suivi-pdf',
      { headers: this.getHttpHeaders(), params: params, responseType: 'blob', observe: 'response' }
    );

    observableResponse.subscribe(
      response => {
        if (response.status === 200) {
          let filename = item.libelle;
          if (response.headers.has("content-disposition")) {
            const cd: string = response.headers.get("content-disposition");
            if (cd.indexOf("filename=") > 0 && cd.indexOf(".pdf") > 0) {
              filename = cd.substring(cd.indexOf("filename=") + ("filename=").length, cd.indexOf(".pdf") + (".pdf").length);
            }
          }

          this.toasterService.pop('info', 'Téléchargement en cours du fichier PDF', item.libelle);

          const contentType = response.headers.get('content-type').toString();
          const blob = response.body; // new Blob([response.body], { type: contentType });
          FileSaver.saveAs(blob, filename);
        } else {
          this.toasterService.pop('error', 'Erreur(s) de téléchargement du fichier : ' + item.libelle, '');
        }
      }, error => {
        console.log(error);
        this.toasterService.pop('error', 'Erreur(s) de téléchargement du fichier : ' + item.libelle, error.statusText);
      });
  }

  public getModelePdf(item: Suivi) {
    let params = new HttpParams();
    params = params.set('typeIden', item.typeIden.toString());

    const observableResponse = this.http.get(this.baseUrl + 'api/suivis/modele-pdf',
      { headers: this.getHttpHeaders(), params: params, responseType: 'blob', observe: 'response' }
    );

    observableResponse.subscribe(
      response => {
        if (response.status === 200) {
          let filename = item.libelle;
          if (response.headers.has("content-disposition")) {
            const cd: string = response.headers.get("content-disposition");
            if (cd.indexOf("filename=") > 0 && cd.indexOf(".pdf") > 0) {
              filename = cd.substring(cd.indexOf("filename=") + ("filename=").length, cd.indexOf(".pdf") + (".pdf").length);
            }
          }

          this.toasterService.pop('info', 'Téléchargement en cours du modèle PDF', item.libelle);

          const contentType = response.headers.get('content-type').toString();
          const blob = response.body; // new Blob([response.blob()], { type: contentType });
          FileSaver.saveAs(blob, filename);
        } else {
          this.toasterService.pop('error', 'Erreur(s) de téléchargement du modèle PDF : ' + item.libelle, '');
        }
      }, error => {
        console.log(error);
        this.toasterService.pop('error', 'Erreur(s) de téléchargement du modèle PDF : ' + item.libelle, error.statusText);
      });
  }

  private _contactsMinot: Contact[] = [];

  public getContactsMinot(): Observable<Contact[]> {
    if (this._contactsMinot.length > 0)
      return of(this._contactsMinot);

    const observableResponse = this.http.get<Contact[]>(this.baseUrl + 'api/societe/contactsMinot', { headers: this.getHttpHeaders(), observe: 'response' });
    observableResponse.pipe(catchError(err => Observable.throw(this.errorHandler(err))));
    return observableResponse.pipe(map(response => {
      if (response.status === 200) {
        this._contactsMinot = response.body;
      }
      return this._contactsMinot;
    }));
  }
}
