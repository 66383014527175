import { PipeTransform, Pipe } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'myDateFormat'
})
export class MyDateFormatPipe implements PipeTransform {
    transform(val: string, format: string = 'DD/MM/YYYY'): string {
        if (val) {
            moment.locale('fr');
            return moment(val, 'YYYY-MM-DD').format(format);
        }
        return '';
    }
}
