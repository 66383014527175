import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanActivateGuard } from './_template/services/can-activate-guard.service';
import { LayoutAuthComponent } from './_template/layouts/auth/auth';
import { LayoutLoginComponent } from './_template/layouts/login/login.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './_guards/auth.guard';

const routes: Routes = [
    // Logged Routes
    {
        canActivate: [AuthGuard, CanActivateGuard],
        path: '',
        component: LayoutAuthComponent,
        data: [{
            'skin': 'skin-blue',
            'display_tasks': false,
            'display_messages': false,
            'display_notifications': false,
            'display_menu_user': false,
            'display_menu_search': false,
            'display_control': false,
            'display_control_sidebar': false,
            'display_footer': false
        }],
        children: [
            {
                canActivate: [CanActivateGuard],
                loadChildren: './home/home.module#HomeModule',
                path: ''
            },
            {
                canActivate: [CanActivateGuard],
                loadChildren: './home/home.module#HomeModule',
                path: 'tableaudebord'
            },
            {
                canActivate: [CanActivateGuard],
                loadChildren: './societe/societe.module#SocieteModule',
                path: 'societe'
            },
            {
                canActivate: [CanActivateGuard],
                loadChildren: './attestations/attestations.module#AttestationsModule',
                path: 'attestations'
            }
        ]
    },

    // Anonym Routes
    {
        path: 'login',
        component: LayoutLoginComponent,
        children: [{
            component: LoginComponent,
            path: ''
        }]
    },

    // Redirections
    { path: 'logout', redirectTo: 'login' }, 
    { path: '**', redirectTo: 'tableaudebord' } 
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes);
