import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyDateFormatPipe } from './_pipes/dateFormat.pipe';
import { FormsModule } from '@angular/forms';
import { MyTelephoneFormatPipe } from './_pipes/telephoneFormat.pipe';
import { NgxfUploaderModule } from 'ngxf-uploader';
import { MyControleStatutFormatPipe } from './_pipes/controleStatut.pipe';
import { ModalContactService } from './widgets/modal-contact/modal-contact.service';
import { ModalContactComponent } from './widgets/modal-contact/modal-contact.component';
import { ModalContactFormComponent } from './widgets/modal-contact/modal-contact-form.component';

@NgModule({
  declarations: [
    MyDateFormatPipe,
    MyTelephoneFormatPipe,
    MyControleStatutFormatPipe,
    ModalContactComponent,
    ModalContactFormComponent
  ],
  providers: [
    ModalContactService
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxfUploaderModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    NgxfUploaderModule,

    ModalContactComponent,
    ModalContactFormComponent,

    MyDateFormatPipe,
    MyTelephoneFormatPipe,
    MyControleStatutFormatPipe
  ]
})
export class SharedModule { }
