import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Contact } from '../../_models/contact';

@Injectable({
  providedIn: 'root'
})
export class ModalContactService {

  private modal = new Subject<Contact>();

  constructor() {

  }

  afficherContact(modal: Contact) {
    this.modal.next(modal);
  }

  getObservable(): Observable<Contact> {
    return this.modal.asObservable();
  }

}
