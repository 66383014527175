import { Component, OnInit } from '@angular/core';
import { MenuService } from './_template/services/menu.service';
import { LogoService } from './_template/services/logo.service';
import { FooterService } from './_template/services/footer.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  public constructor(
    private logoService: LogoService,
    private footerService: FooterService,
    private menuService: MenuService) {
    this.logoInit();
    this.menuInit();
    this.footerInit();
  }

  private footerInit() {
    this.footerService.setCurrent({ right_part: 'MAGEST - 2018', left_part: '' });
  }

  private logoInit() {
    this.logoService.setCurrent({
      html_mini: '<b>A</b>',
      html_lg: '<b>Attestation</b>'
    });
  }

  private menuInit() {
    const mylinks = [
      {
        'header': 'GENERAL'
      },
      {
        'title': 'Tableau de bord',
        'icon': 'dashboard',
        'link': ['/tableaudebord']
      },
      {
        'title': 'Ma société',
        'icon': 'industry',
        'link': ['/societe']
      },
      {
        'header': 'SUIVI ADMINISTRATIF'
      },
      {
        'title': 'Mes attestations',
        'icon': 'file',
        'link': ['/attestations']
      },
    ];
    this.menuService.setCurrent(mylinks);
  }

  public ngOnInit() {
  }

}
