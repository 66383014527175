import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../_template/services/user.service';
import { AuthService } from '../_services/auth.service';
import { isNullOrUndefined } from 'util';
import { ToasterService } from 'angular2-toaster';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  model: any = {};
  loading = false;
  erreurs = '';

  constructor(
    private toasterService: ToasterService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private router: Router) { }

  ngOnInit() {
    this.authService.logout();

    const sub0 = this.activatedRoute.queryParams.subscribe(params => {
      const la = params['la'];
      const baseTest: boolean = (params['test'] && params['test'] == '1' ? true : false);
      if (!la) { return; }

      const sub1 = this.authService.la(la, baseTest)
        .subscribe(
          result => {
            this.loginResult(result);
          },
          error => {
            this.erreurs = 'ERREUR : La connexion a échoué, merci de vérifier vos identifiants !';
            this.loading = false;
          }
      );
      this.subscriptions.push(sub1);
    });
    this.subscriptions.push(sub0);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  login() {
    this.loading = true;
    const sub2 = this.authService.login(this.model.username, this.model.password)
      .subscribe(
        result => {
          this.loginResult(result);
        },
        error => {
          this.erreurs = 'ERREUR : La connexion a échoué, merci de vérifier vos identifiants !';
          this.loading = false;
        }
    );
    this.subscriptions.push(sub2);
  }

  private loginResult(result) {
    if (result.code === 200) {
      const authRoute = localStorage.getItem('authRoute');
      if (isNullOrUndefined(authRoute)) {
        this.router.navigate(['tableaudebord']);
      } else {
        localStorage.setItem('authRoute', '');
        this.router.navigate([authRoute]);
      }
    } else {
      this.erreurs = 'ATTENTION : La connexion a échoué, merci de vérifier vos identifiants !';
      if (result.code === 401) {
        this.erreurs = 'ATTENTION : La connexion a échoué, le mot de passe est incorrect !';
      } else if (result.code === 402) {
        this.erreurs = 'ATTENTION : La connexion a échoué, votre compte a été verrouillé !';
      } else if (result.code === 403) {
        this.erreurs = 'ATTENTION : La connexion a échoué, votre compte n\'est pas actif !';
      } else if (result.code === 404) {
        this.erreurs = 'ATTENTION : La connexion a échoué, votre identifiant est introuvable !';
      }

      this.loading = false;
    }
  }

    motdepasseOublie(event, login: string) {
    if (login) {
      const sub3 = this.authService.postMotDePasseOublie(login).subscribe(value => {
        if (value) {
          this.toasterService.pop('info', 'Un nouveau mot de passe vous a été envoyé par email. Merci de vérifier vos courriels indésirables. En cas de problème, contactez nous au 04.74.02.74.88.');
        } else {
          this.toasterService.pop('error', 'Mot de passe oublié', 'Erreur(s) lors de la récupération de votre compte.');
        }
      });
      this.subscriptions.push(sub3);
    } else {
      this.toasterService.pop('error', 'Votre identifiant doit être renseigné.');
    }
  }
}
